<template>
  <div class="_wrap">

    <phoneMenu :menu="p_menu" :p_current="p_current" @changeRoute="changeRoute"></phoneMenu>

    <div class="_swiper" id="gpzp">
      <el-carousel height="490">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img v-if="seen" :src="$IMG_URL + item.cover" alt="">
          <img v-if="!seen" :src="$IMG_URL + item.phone_cover" alt="">

        </el-carousel-item>
      </el-carousel>
    </div>


    <div class="_content">
      <div class="_auto">


        <div class="_cplb" id="cpsjk">
          <div class="_title"></div>

          <div class="_types">

            <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" class="_myform">


              <el-form-item label="" prop="times_id">
                <el-select v-model="queryParams.times_id" placeholder="请选择年代" clearable>
                  <el-option v-for="(item, index) in $store.state.homeData.years" :key="index" :label="item.times"
                    :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="type_id">
                <el-select v-model="queryParams.type_id" placeholder="请选择分类" clearable>
                  <el-option v-for="(item, index) in $store.state.homeData.textures" :key="index" :label="item.type"
                    :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="keywords">
                <el-input v-model="queryParams.keywords" placeholder="请输入关键词" clearable
                  @keyup.enter.native="handleQuery" />
              </el-form-item>
            </el-form>

            <el-button class="_btn" size="mini" @click="handleQuery">搜索</el-button>
          </div>

          <div class="_list">
            <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
              <div class="_top">
                <img :src="$IMG_URL + item.cover" alt="">
                <div class="_bo">
                  {{ item.title }}
                </div>
              </div>

            </div>


          </div>

          <div class="_takePage">

            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page"
              :limit.sync="queryParams.page_num" @pagination="getList" />
          </div>



        </div>
      </div>





      <div class="_cpzm" id="cpml">
        <div class="_cpzm_auto">
          <div class="_tite"></div>
          <div class="_th">
            <p class="_th_name">文物名称</p>
            <p class="_sd">年代</p>
            <p class="_fl">分类</p>


          </div>
          <div class="_list">

            <div class="_li" v-for="(item, index) in list1" :key="index">
              <p class="_name">
                <span> {{ item.title }}</span>
              </p>
              <p class="_cz">{{ item.times_detail }}</p>
              <p class="_time _impact">{{ item.texture }}</p>

            </div>


          </div>


          <div class="_takePage">

            <pagination v-show="total1 > 0" :total="total1" :page.sync="queryParams1.page"
              :limit.sync="queryParams1.page_num" @pagination="getList1" />
          </div>

        </div>
      </div>



      <div class="_cpzj" id="cpzj">

        <div class="_cpauto">
          <div class="_title"></div>
          <div class="_cont">
            <div class="_html" ref="myHtml" v-html="zjDetail.content"></div>
          </div>
        </div>
      </div>


      <coment ref="comentRef"></coment>
      <mainFooter ref="mainFooterRef"></mainFooter>
    </div>


    <fixIcon />
  </div>
</template>

<script>
import { getDataById } from '../../api/getData';
import phoneMenu from '../../components/phoneMenu/phoneMenu.vue';

export default {
  components: {
    phoneMenu,
  },
  data() {
    return {
      p_current: 0,
      title: "",
      obj: {},


      total: 0,
      queryParams: {
        page: 1,
        page_num: 12,
        times_id: "",
        type_id: "",
        keywords: "",
      },
      total1: 0,
      queryParams1: {
        page: 1,
        page_num: 13,
      },
      bannerList: [],
      list: [],
      list1: [],
      zjDetail: [],
      seen: true,
      p_menu: [
        {
          text: "馆藏珍品",
          path: "/education"
        },
        {
          text: "藏品数据库",
          path: "/education"
        },
        {
          text: "藏品目录",
          path: "/education"
        },
        {
          text: "藏品征集",
          path: "/education"
        },
      ],


    }
  },

  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      console.log(to);
      let title = to.query.tit
      this.title = title
      if (title) {
        for (let i in this.obj) {
          if (this.title == i) {
            this.$scrollToSection(this.obj[i])
          }
        }

        this.p_menu.forEach((item, index) => {
          if (title == item.text) {
            this.p_current = index
          }
        })
      } else {
        this.$scrollTop()
      }

    }
  },
  created() {

  },
  async mounted() {
    this.menuSeen()
    await this.getBanner()
    await this.getList()
    await this.getList1()
    await this.getZj()
    this.jumpId()
  },
  methods: {
    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    changeRoute(index) {
      this.p_current = index
    },
    jumpId() {
      const title = this.$route.query.tit
      console.log(title)
      const obj = {
        '馆藏珍品': 'gpzp',
        '藏品数据库': 'cpsjk',
        '藏品目录': 'cpml',
        '藏品征集': 'cpzj',
      }
      this.obj = obj
      if (title) {
        for (let i in obj) {
          console.log(i)
          if (title == i) {
            this.$scrollToSection(obj[i])
          }
        }
      } else {
        this.$scrollTop()
      }

    },

    handleQuery() {
      console.log(this.queryParams)
      this.queryParams.page = 1
      this.list = []
      this.getList()
    },





    async getBanner() {
      const params = {
        cate_id: 26,
        page: 1,
        page_num: 100,
        keywords: "",
        type_id: "",
        times: "",
      }
      await getDataById(params).then(res => {
        console.log(res)
        this.bannerList = res.data.data

      })
    },

    async getList() {
      const params = {
        cate_id: 27,
        page: this.queryParams.page,
        page_num: this.queryParams.page_num,
        keywords: this.queryParams.keywords,
        type_id: this.queryParams.type_id,
        times_id: this.queryParams.times_id,
      }
      await getDataById(params).then(res => {
        console.log(res)
        this.list = res.data.data
        this.total = res.data.total

      })
    },
    async getList1() {
      const params = {
        cate_id: 28,
        page: this.queryParams1.page,
        page_num: this.queryParams1.page_num,
        keywords: "",
        type_id: "",
        times: "",
      }
      await getDataById(params).then(res => {
        console.log(res)
        this.list1 = res.data.data
        this.total1 = res.data.total

      })
    },
    async getZj() {
      const params = {
        cate_id: 29,
        page: 1,
        page_num: 10,
        keywords: "",
        type_id: "",
        times: "",
      }
      await getDataById(params).then(res => {
        console.log(res)

        this.zjDetail = res.data.data[0]
      })
    },

    goDetail(item) {
      let url = window.origin

      if (this.seen) {
        window.open(`${url}/educationDetail?tit=馆藏文物&id=${item.id}`)

      } else {
        this.$router.push({
          path: "/educationDetail",
          query: {
            tit: "馆藏文物",
            id: item.id
          }
        })
      }
    },


  }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

@mixin tit($url)
{
  width: 299px;
  height: 52px;
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

._wrap
{
  width: 100%;
  overflow: hidden;

  background-color: #F8F5EE;
}

._swiper
{
  width: 100%;

  margin-top: 120px;
  // background-color: #FDFAF6;

  img
  {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


}

._content
{
  width: 100%;
  overflow: hidden;
  // position: absolute;
  // top: 350px;

  ._auto
  {
    width: 85%;
    margin: 0 auto;




    ._gczp
    {
      h4
      {
        font-size: 60px;
        color: white;
        margin-bottom: 26px;

      }

      p
      {
        color: white;
        font-size: 20px;
        letter-spacing: 1px;
        width: 84%;
      }
    }

    ._cplb
    {
      margin-top: 86px;

      ._title
      {
        @include tit("../../assets/images/h4-15.png");

        margin: 0 auto;
        margin-bottom: 40px;
      }

    }


    ._types
    {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
    }

    ._list
    {
      display: flex;
      flex-wrap: wrap;
      min-height: 310px;
      // justify-content: space-between;


      ._li
      {
        width: 15.83%;
        height: 346px;
        position: relative;
        cursor: pointer;

        margin-right: 1%;
        margin-bottom: 20px;

        &:nth-child(6n)
        {
          margin-right: 0;
        }

        ._top
        {
          width: 100%;
          height: 100%;
          overflow: hidden;

          img
          {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .5s;
          }

          img:hover
          {
            transform: scale(1.1);

          }

        }

        ._top:hover ._bo
        {
          display: -webkit-box;

        }

        ._bo
        {
          // background-color: #fff;
          width: 90%;
          color: white;
          font-size: 18px;
          position: absolute;
          bottom: 23px;
          left: 15px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /* 设置最大显示行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          display: none;



        }
      }


    }


  }





  ._cpzm
  {
    ._cpzm_auto
    {
      margin: 0 auto;
      width: 75%;

      ._tite
      {
        @include tit("../../assets/images/h4-16.png");
        margin: 0 auto;
        margin-bottom: 40px;
      }



      ._th
      {
        display: flex;
        align-items: center;
        padding: 15px 0 15px 43px;
        // justify-content: space-between;
        color: #333;
        background-color: #EEEAE1;

        >p
        {
          font-size: 24px;

        }

        ._th_name
        {
          width: 70%;
        }

        ._sd
        {
          width: 15%;
        }

        ._fl
        {
          width: 15%;
        }

        >div
        {
          display: flex;
          align-items: center;

          p
          {
            font-size: 20px;
            font-weight: bold;

          }

          ._shu
          {
            margin: 0 53px;
          }
        }
      }

      ._list
      {
        background-color: #fff;

        ._li
        {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          padding: 28px 0 24px 43px;
          border-bottom: 1px solid #E4E4E4;
          cursor: pointer;

          p
          {
            font-size: 18px;
            color: #333;
          }

          ._name
          {
            font-size: 20px;
            letter-spacing: 1px;
            color: #333;
            width: 68%;

            span
            {
              font-size: 20px;
              letter-spacing: 1px;
              color: #333;
              width: 100%;


            }


          }


          ._cz
          {
            width: 15%;
            margin-left: 35px;
          }


          ._time
          {
            width: 15%;

          }

        }
      }
    }
  }
}

._cpzj
{
  ._cpauto
  {
    width: 85%;
    margin: 0 auto;

    ._title
    {
      @include tit("../../assets/images/h4-17.png");
      margin: 0 auto;
      margin-bottom: 40px;
    }

    ._cont
    {
      height: 789px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #D8D0C5;
      overflow-y: auto;

      ._html
      {
        padding: 50px;
      }
    }
  }
}


::v-deep .el-form--inline .el-form-item__label
{
  color: white;
  font-size: 18px;
  padding-right: 7px;
  line-height: 40px;
}

::v-deep .el-form--inline .el-form-item
{
  margin-right: 54px;
}

::v-deep .el-input__inner
{
  background: white !important;
  border-radius: inherit;
  // color: white;
  height: 40px;
  line-height: 40px;
}

::v-deep .el-select .el-input__inner:focus
{
  border-color: #C0C4CC;
}

::v-deep .el-input__suffix
{
  // height: 80%;
}

::v-deep .el-input--small .el-input__icon
{
  // line-height: 0px;
}


._takePage
{
  text-align: center;
  margin: 60px 0 100px;
}

._btn
{
  color: white;
  background-color: #A5322B;
  width: 253px;
  height: 48px;
  font-size: 18px;

}


::v-deep._swiper
{
  @include respondTo('phone')
  {
    margin-top: 0;


    .el-carousel__container
    {
      height: 390px;
    }

    img
    {
      width: 100%;
      height: 390px;
    }
  }
}


._content
{
  @include respondTo('phone')
  {
    ._auto
    {
      width: 95% !important;
      padding: 0;

      ._cplb
      {
        margin-top: 30px;

        ._title
        {
          background-image: url("../../assets/images/phone/cpsjk.png");
          width: 173px;
          height: 30px;
          margin-bottom: 30px;
        }

        ._types
        {
          display: block;
          position: relative;

          ._myform
          {
            display: flex;
            flex-wrap: wrap;

            .el-form-item
            {
              width: 47%;
              margin-right: 10px;
            }
          }

          ._btn
          {
            width: 47%;
            position: absolute;
            right: 10px;
            bottom: 18px;
            height: 40px;
          }



        }

        ._list
        {

          ._li
          {
            width: 32.32%;
            height: 150px;
            margin-right: 1.5%;

            ._bo
            {
              display: block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          ._li:nth-child(3n)
          {
            margin-right: 0;
          }
        }

        ._takePage
        {
          margin: 10px 0 30px;
        }
      }
    }


    ._cpzm
    {

      ._cpzm_auto
      {
        width: 95%;

        ._tite
        {
          background-image: url("../../assets/images/phone/cpml.png");
          width: 173px;
          height: 30px;
          margin-bottom: 20px;
        }

        ._th
        {
          padding: 10px;

          ._th_name
          {
            width: 50%;
            font-size: 18px;

          }

          ._sd
          {
            width: 25%;
            font-size: 18px;

          }

          ._fl
          {
            width: 25%;
            font-size: 18px;
            text-align: center;
          }
        }

        ._list
        {
          ._li
          {
            padding: 10px;

            ._name
            {
              width: 45%;
            }

            ._cz
            {
              margin-left: 30px;
              width: 35%;
            }

            ._time
            {
              width: 25%;
              text-align: center;
            }
          }
        }

        ._takePage
        {
          margin: 20px 0 30px;
        }
      }
    }



    ._cpzj
    {
      margin-bottom: 30px;

      ._cpauto
      {
        width: 95%;

        ._title
        {
          background-image: url("../../assets/images/phone/cpzj.png");
          width: 173px;
          height: 30px;
          margin-bottom: 30px;
        }

        ._html
        {
          padding: 10px;
        }
      }
    }
  }
}
</style>